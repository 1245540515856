<template>
  <v-hover v-slot="{hover}">
    <div     @focusout="finishEdit($event)">
       <span :contenteditable="edit ? 'plaintext-only' : 'false'"

             ref="text"
             style="outline: none; "
             :style="edit ? 'cursor : text' : ''"
             class="font-weight-medium">
         {{ text }}
      </span>
      <v-fade-transition>
        <v-icon @click="startEdit()" v-ripple v-if="buttonDisplayCondition(hover)" :color="wsACCENT">mdi-pencil</v-icon>
      </v-fade-transition>
    </div>

  </v-hover>

</template>

<script>
export default {
  name: "wsPlainTextEdit",
  props : {
    value : {
      type : String,
      default : ''
    },
    hover : {
      type : Boolean,
      default : false
    },
    button : {
      type : Boolean,
      default : true
    },
    displayButtonOnHover : {
      type : Boolean,
      default : true
    }
  },
  data() {
    return {
      text : '',
      edit : false,
    }
  },
  watch: {
    value() {
      if ( this.value !== this.text ) {
        this.text = this.value
      }
    },
  },
  methods : {
    startEdit() {
      let text = this.$refs.text
      this.edit = true
      this.$nextTick(() => {
        text.focus()
        var r=new Range(), sel=getSelection(); r.setStart(text.lastChild, text.lastChild.length||1);sel.removeAllRanges();sel.addRange(r)
      })
    },
    finishEdit($event) {
      this.edit = false
      let text = this.$refs.text
      this.text = $event.target.innerHTML
          .replace(/&nbsp;/g, " ")
      text.innerHTML = this.text

      this.$emit('input',this.text)
    },
    buttonDisplayCondition(hover) {
      if ( !this.button ) {
        return false
      }
      if ( this.displayButtonOnHover) {
        if ( this.hover ) {
          return this.hover
        }
        if ( hover ) {
          return hover
        }
      }
      return true
    }
  },
  mounted() {
    if (this.value ) {
      this.text = this.value
    }
  }
}
</script>

<style scoped>

</style>